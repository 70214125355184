import './utils/vh.utils.js';
import './components/gsap-animation.component.js';
import './components/jb-swiper.component.js';
import './components/dev-swiper.component.js';
import './components/consultant.component.js';
import './components/choices.component.js';
import './components/solutions-swiper.component.js';
import './components/inputs.component.js';
import './components/titleBg.component.js';
import './components/custom.component.js';
import './utils/animate-on-view.utils.js';

import Swiper, {Autoplay, Scrollbar} from 'swiper';
import scrollLock from 'scroll-lock';
import {CountUp} from 'countup.js';

// countUp
let countUpArr = new Array();

function CountUpFunction(id, finalNum, option = {}) {
    let defaultOptions = {
        separator: ',',
        enableScrollSpy: true,
        scrollSpyOnce: true,
    };

    let mergeOptions = {...defaultOptions, ...option};
    countUpArr.push(new CountUp(id, finalNum, mergeOptions));
}

function counter(elements) {
    elements.forEach((counter, i) => {
        const value = counter.getAttribute('data-count');
        CountUpFunction(counter, value, {suffix: ''});
    });
}

if (document.querySelector('.stats__num')) {
    const counters = document.querySelectorAll('.stats__num');
    counter(counters);
}

let userAgent = navigator.userAgent.toLowerCase();
let safari14 = /version\/14.*safari/gi.test(userAgent);
if (safari14) document.querySelector('body').classList.add('safari-14-support');

// swiper init on resize
const resizableSwiper = (breakpoint, swiperClass, swiperSettings, callback) => {
    let swiper;

    breakpoint = window.matchMedia(breakpoint);

    const enableSwiper = function (className, settings) {
        swiper = new Swiper(className, settings);

        if (callback) {
            callback(swiper);
        }
    };

    const checker = function () {
        if (breakpoint.matches) {
            return enableSwiper(swiperClass, swiperSettings);
        } else {
            if (swiper !== undefined) swiper.destroy(true, true);
            return;
        }
    };

    breakpoint.addEventListener('change', checker);
    checker();
};
// swiper init on resize

// scroll smooth
/*
const scrollTo = document.querySelector('.hero__scroll')
if(scrollTo){
	scrollTo.addEventListener('click', (e) => {
		e.preventDefault()
		lenis.scrollTo('#about_ancor')
	})
}

const lenis = new Lenis()
*/

window.SmoothScroll({stepSize: 100});

/*function raf(time) {
    lenis.raf(time)
    requestAnimationFrame(raf)
}

requestAnimationFrame(raf)*/

// burger-menu
const burgerMenu = (menuSelector, burgerSelector) => {
    const menuElem = document.querySelector(menuSelector),
        layoutElem = document.querySelector('.header__layout'),
        burgerElem = document.querySelector(burgerSelector);

    const removeClass = () => {
        menuElem.classList.remove('active');
        scrollLock.enablePageScroll();
    };
    removeClass();

    menuElem.querySelectorAll('a').forEach((i) => {
        i.addEventListener('click', (e) => {
            if (
                menuElem.classList.contains('active') &&
                !i.classList.contains('open')
            ) {
                removeClass();
            }
        });
    });

    burgerElem.addEventListener('click', () => {
        burgerElem.classList.toggle('active');
        if (menuElem.classList.contains('active')) {
            removeClass();
        } else {
            menuElem.classList.add('active');
            var method = 'margin';
            scrollLock.setFillGapMethod(method);
            scrollLock.disablePageScroll();

            if (document.querySelector('.header__simplebar') && innerWidth < 1024) {
                scrollLock.addScrollableSelector(
                    '.header__simplebar .simplebar-content-wrapper'
                );
            }

            scrollLock.addFillGapSelector('.header__navbar-box');
        }
        console.log(scrollLock.getScrollState());
    });

    window.addEventListener('resize', () => {
        if (innerWidth > 1023) {
            removeClass();
        }
    });

    layoutElem.addEventListener('click', () => {
        removeClass();
    });
};

// sticky

function stickyMenu() {

        const headerElem = document.querySelector('.header');
        const headerWrapper = document.querySelector('.header__wrapper');

        if (window.pageYOffset >= 50) {
            headerElem.classList.add('sticky');
        } else {
            headerElem.classList.remove('sticky');
        }

        const headerHeight = document.documentElement.style.setProperty(
            '--header-height',
            `${headerWrapper.scrollHeight}px`
        );

}

window.addEventListener('scroll', stickyMenu);
stickyMenu();
// end sticky

if (document.querySelector('.header')) {
    burgerMenu('.header', '.header__burger');
}

function activeOnView(animElem) {
    const elements = document.querySelectorAll(animElem);

    elements.forEach((element) => {
        if (
            element.getBoundingClientRect().top < innerHeight / 2 &&
            element.getBoundingClientRect().bottom > innerHeight / 2 &&
            !element.classList.contains('is-viewed')
        ) {
            element.classList.add('is-viewed');
        }
    });
}

activeOnView('.is-anim');
window.addEventListener('scroll', () => activeOnView('.is-anim'));

// sliders
if (document.querySelector('.solutions__slider')) {
    resizableSwiper('(max-width: 1023px)', '.solutions__slider', {
        modules: [Autoplay, Scrollbar],
        slidesPerView: 1,
        spaceBetween: 10,
        speed: 500,
        autoplay: {
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
        },
        breakpoints: {
            658: {
                slidesPerView: 2,
                spaceBetween: 25,
            },
        },
        scrollbar: {
            el: '.solutions__scrollbar',
            draggable: true,
        },
    });
}
if (document.querySelector('.unlock__slider')) {
    new Swiper('.unlock__slider', {
        modules: [Autoplay, Scrollbar],
        slidesPerView: 1,
        spaceBetween: 20,
        speed: 500,
        autoplay: {
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
        },
        breakpoints: {
            640: {
                slidesPerView: 2,
            },
            1024: {
                slidesPerView: 3,
            },
            1100: {
                slidesPerView: 4,
            },
        },
        scrollbar: {
            el: '.unlock__scrollbar',
            draggable: true,
        },
    });
}
if (document.querySelector('.different__slider')) {
    new Swiper('.different__slider', {
        modules: [Autoplay, Scrollbar],
        slidesPerView: 1,
        spaceBetween: 10,
        speed: 500,
        autoplay: {
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
        },
        breakpoints: {
            658: {
                slidesPerView: 2,
                spaceBetween: 25,
            },
            1200: {
                slidesPerView: 2,
                spaceBetween: 60,
            },
        },
        scrollbar: {
            el: '.different__scrollbar',
            draggable: true,
        },
    });
}
if (document.querySelector('.jobs__slider')) {
    new Swiper('.jobs__slider', {
        modules: [Autoplay, Scrollbar],
        slidesPerView: 1,
        spaceBetween: 10,
        speed: 500,
        autoplay: {
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
        },
        breakpoints: {
            550: {
                slidesPerView: 1.4,
                spaceBetween: 25,
            },
            700: {
                slidesPerView: 1.8,
                spaceBetween: 40,
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 40,
            },
        },
        scrollbar: {
            el: '.jobs__scrollbar',
            draggable: true,
        },
    });
}
if (document.querySelector('.blogs__slider')) {
    new Swiper('.blogs__slider', {
        modules: [Autoplay],
        slidesPerView: 1,
        spaceBetween: 0,
        speed: 500,
        autoplay: {
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
        },
        breakpoints: {
            658: {
                slidesPerView: 2,
            },
            900: {
                slidesPerView: 3,
            },
            1200: {
                slidesPerView: 4,
            },
        },
    });
}
// end sliders

$('.filters-title').on('click', function () {
    $(this).toggleClass('active');
    $(this).next('.filters-list').stop(true, true).slideToggle();
});

$('a[href^="#"]').click(function () {
    let target = $(this).attr('href');
    if ($(target).length) {
        $('html, body').animate(
            {
                scrollTop: $(target).offset().top - 50,
            },
            800
        );
        return false;
    }
});

const headerLinks = document.querySelectorAll('.header__link');
if (headerLinks.length) {
    function setWidth() {
        headerLinks.forEach((link) => {
            const text = link.querySelector('span');
            if (text) {
                if (window.innerWidth < 1024) {
                    link.style.width = '100%';
                    link.offsetWidth > text.offsetWidth
                        ? (link.style.width = `${text.offsetWidth + 1}px`)
                        : false;
                } else {
                    link.style.width = '';
                }
            }
        });
    }

    window.onresize = () => setWidth();
    setWidth();
}

window.openCeoPopup = () => {
    const popup = document.querySelector('#popup');

    popup.classList.add('active');
    window.scrollLock.disablePageScroll(popup);
    window.scrollLock.addScrollableSelector('.simplebar-content-wrapper');
    initTitleGradient();
};

