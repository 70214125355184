
let titleGradientEl = document.querySelectorAll('.title-js-gradient-bg .row');
window.initTitleGradient = function initTitleGradient(titles){
    if (titles) {
        titles.forEach(row => {
            let span = row.querySelector('span');
            if (span) {
                row.style.width = '100%';
                row.offsetWidth > span.offsetWidth ? row.style.width = `${span.offsetWidth + 1}px` : false;
            }

            setTimeout(function(){
                let parent = row.closest('.title-js-gradient-bg');
                let parentWidth = parent.clientWidth + 1;
                let parentHeight = parent.clientHeight;
                let bgPositionLeft = row.offsetLeft;
                let bgPositionTop = row.offsetTop;

                row.style.backgroundSize  = `${parentWidth}px ${parentHeight}px`;
                row.style.backgroundPosition  = `${-bgPositionLeft}px ${-bgPositionTop}px`;
                row.classList.add('gr-init');
            }, 50)
        })
    }
}

window.addEventListener('resize', function(){
    initTitleGradient(titleGradientEl)
});
initTitleGradient(titleGradientEl);

