// import Swiper, {Autoplay, Navigation} from 'swiper';
// import {swiperObserver} from '../utils/swiper.utils.js';

// let solutionSwiperWr = document.querySelectorAll('.solutions-swiper-wr')l
// solutionSwiperWr.forEach(el => {
//     if (el) {
//         let swiperEl = el.querySelector('.swiper');
//         let nextEl = el.querySelector('.swiper-button-next');
//         let prevEl = el.querySelector('.swiper-button-prev');
//         let slidesCount = el.querySelectorAll('.swiper-slide').length;
//         let swiper = new Swiper(swiperEl, {
//             modules: [Autoplay],
//             spaceBetween: 12.5,
//             slidesPerView: 1,
//             speed: 800,
//             threshold: 10,
//             autoplay: {
//                 delay: 5000,
//                 disableOnInteraction: false,
//                 pauseOnMouseEnter: false,
//             },
//             breakpoints: {
//                 600: {
//                     spaceBetween: 10,
//                     slidesPerView: 2,
//                 },
//                 992: {
//                     spaceBetween: 10,
//                     slidesPerView: 3,
//                 },
//                 1200: {
//                     slidesPerView: 4,
//                     spaceBetween: 11.5,
//                 }
//             }
//         })
//         swiperObserver(swiper);
//     }
// })
