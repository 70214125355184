import Swiper, { Autoplay, Scrollbar } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let jbSwiperWr = document.querySelectorAll('.jb-swiper-wr');
jbSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let scrollBar = el.querySelector('.swiper-scrollbar');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Scrollbar],
      spaceBetween: 20,
      slidesPerView: 1,
      speed: 800,
      threshold: 10,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
      scrollbar: {
        el: scrollBar,
        draggable: true,
      },
      /* navigation: {
                nextEl: nextEl,
                prevEl: prevEl,
            },*/
      breakpoints: {
        550: {
          slidesPerView: 2,
          spaceBetween: 25,
        },
        750: {
          slidesPerView: 3,
          spaceBetween: 25,
        },

        1200: {
          slidesPerView: 4,
          spaceBetween: 67,
        },
      },
    });
    swiperObserver(swiper);
  }
});

const initTechSwiper = () => {
  let devSwiperWr = document.querySelectorAll('.dev-stack-swiper-wr');
  devSwiperWr.forEach((el) => {
    if (el) {
      let swiperEl = el.querySelector('.swiper');

      let nextEl = el.querySelector('.swiper-button-next');
      let prevEl = el.querySelector('.swiper-button-prev');
      let slidesCount = el.querySelectorAll('.swiper-slide').length;
      let swiper = new Swiper(swiperEl, {
        modules: [Autoplay],
        spaceBetween: 25,
        slidesPerView: 'auto',
        speed: 800,
        threshold: 10,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
          pauseOnMouseEnter: false,
        },
        breakpoints: {
          1100: {
            spaceBetween: 58,
          },
        },
      });

      swiperObserver(swiper);
    }
  });
};
initTechSwiper();

const jobCards = document.querySelectorAll('.jb-card');

if (jobCards.length) {
  const jobSection = document.querySelector('.dev-section');

  const title = jobSection.querySelector('#jb-title');
  const techSliderWrap = jobSection.querySelector('#jb-tech-slider-wrap');
  const description = jobSection.querySelector('#jb-description');
  const trends = jobSection.querySelector('#jb-trends');

  jobCards.forEach((card, idx) => {
    let currentIndex = idx;
    console.log('curr-idx', currentIndex);
    card.addEventListener('click', function() {

      this.classList.toggle('active');
      jobCards.forEach((card, idx)=>{
        if(currentIndex != idx){
          card.classList.remove('active');
        }
      })

      let { jobDescription, jobImg, jobTitle, jobTrends, jobTech } = card.dataset;
      jobTech = Array.from(JSON.parse(jobTech));

      title.textContent = jobTitle;
      description.innerHTML = jobDescription;
      trends.innerHTML = jobTrends;

      if (jobTech.length) {
        techSliderWrap.innerHTML = '';

        const swiperEl = document.createElement('div');
        swiperEl.classList.add('swiper');

        const swiperWrapper = document.createElement('div');
        swiperWrapper.classList.add('swiper-wrapper');

        swiperEl.appendChild(swiperWrapper);

        jobTech.forEach((techItem) => {
          const swiperSlide = document.createElement('div');
          swiperSlide.classList.add('swiper-slide');

          const stackCard = document.createElement('div');
          stackCard.classList.add('stack-card');

          const img = document.createElement('img');
          img.src = techItem;

          stackCard.appendChild(img);
          swiperSlide.appendChild(stackCard);

          swiperWrapper.appendChild(swiperSlide);
        });

        techSliderWrap.appendChild(swiperEl);

        initTechSwiper();
      } else {
        techSliderWrap.innerHTML = '';
      }
    });
  });
}
