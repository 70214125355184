window.autosizeTextarea = function autosizeTextarea() {
  if (document.querySelector('textarea[data-autosize]')) {
    const autosizeTextareaElements = document.querySelectorAll(
      'textarea[data-autosize]'
    );
    autosizeTextareaElements.forEach((textarea) => {
      const startHeight = textarea.offsetHeight;
      textarea.addEventListener('input', autosize);
      function autosize() {
        const el = this;
        const newHeight = el.scrollHeight;
        if (newHeight > startHeight) {
          el.style.minHeight = `${el.scrollHeight}px`;
        } else {
          el.style.minHeight = startHeight;
        }
      }
    });
  }
};
autosizeTextarea();

const uploadInput = document.querySelectorAll('.upload-file-field');

if (uploadInput.length) {
  uploadInput.forEach((input) => {
    const nativeInput = input.querySelector('input[type="file"]');
    const textInput = input.querySelector('span');

    nativeInput.addEventListener('change', ({ target }) => {
      console.dir([...target.files].map((file) => file.name).join(', '));
      textInput.innerText = target.files.length
        ? [...target.files].map((file) => file.name).join(', ')
        : 'Upload CV file';
    });
  });
}

// Contact Form
const contactRadios = document.querySelectorAll('.footer__input-box');
const contactForm = document.querySelector('.contact-us-form-grid');

if (contactRadios.length) {
  contactRadios.forEach((radio) => {
    radio.addEventListener('change', ({ target }) => {
      if (typeof target.dataset.withCv !== 'undefined') {
        contactForm.classList.add('with-cv');
      } else {
        contactForm.classList.remove('with-cv');
      }
    });
  });
}

const { hash } = location;

if (hash) {
  const radio = document.querySelector(`.footer__input-box input${hash}`);
  if(radio){
    radio.checked = true;
    if (radio) {
      let contactSectionOfftesTop =  document.querySelector('.contact-us-section').getBoundingClientRect().top;
      let radioOfftesTop =  radio.getBoundingClientRect().top;
      let scrollPaddingTop = radioOfftesTop - contactSectionOfftesTop - 60;

      document.documentElement.style.setProperty('--scroll-padding-top', scrollPaddingTop + 'px')

      if (typeof radio.dataset.withCv !== 'undefined') {
        contactForm.classList.add('with-cv');
      } else {
        contactForm.classList.remove('with-cv');
      }
    }
  }


}
