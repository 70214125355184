/** @type {import('tailwindcss').Config} */
module.exports = {
  /**
   * FD content
   * **/
  // content: ['./src/**/*.{html,js}'],

  /**
   * SD content
   * **/
  content: [
    './app/modules/**/*.php',
    './app/layout/**/*.php',
    './app/modules/!panel/**',
    './app/public/js/*.js',
  ],
  theme: {
    screens: {
      xs: '360px',
      // => @media (min-width: 360px) { ... }

      sm: '640px',
      // => @media (min-width: 640px) { ... }

      md: '768px',
      // => @media (min-width: 768px) { ... }

      lg: '1024px',
      // => @media (min-width: 1024px) { ... }

      xl: '1280px',
      // => @media (min-width: 1280px) { ... }

      '2xl': '1536px',
      // => @media (min-width: 1536px) { ... }

      '3xl': '1800px',
      // => @media (min-width: 1800px) { ... }
    },

    extend: {
      colors: {
        transparent: 'transparent',
        current: 'currentColor',
        pink: {
          50: '#fef5fc',
          100: '#fee9fd',
          200: '#fbd3f7',
          300: '#f7b0ec',
          400: '#f280de',
          500: '#e23ac5',
          600: '#c930ac',
          700: '#a6258a',
          800: '#882071',
          900: '#701f5c',
          950: '#4a083a',
        },
        blue: {
          50: '#e6f5ff',
          100: '#d1ebff',
          200: '#acd8ff',
          300: '#7abcff',
          400: '#468eff',
          500: '#1d60ff',
          600: '#003cff',
          700: '#003fff',
          800: '#0036dc',
          900: '#052baa',
          950: '#020e3c',
        },
        grey: {
          50: '#f6f7f8',
          100: '#edeef1',
          200: '#dadce3',
          300: '#c1c6cf',
          400: '#a3a8b7',
          500: '#8c92a5',
          600: '#7b7e95',
          700: '#6e7187',
          800: '#5d5e70',
          900: '#4d4e5b',
          950: '#31313a',
        },
      },
      backgroundImage: {
        gradient:
          'linear-gradient(45deg, rgba(103,210,240,1) 0%, rgba(217,57,194,1) 100%)',
        'gradient-alt':
          'linear-gradient(135deg, rgba(103,210,240,1) 0%, rgba(217,57,194,1) 80%)',
      },
      scale: {
        130: '1.3',
      },
    },
  },
  plugins: [],
};
