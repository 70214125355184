const consultantComponent = document.querySelector('#consultant');

function checkOverlap(ele1, ele2) {
	const boundings1 = ele1.getBoundingClientRect();
	const boundings2 = ele2.getBoundingClientRect();
	const bottom1 = boundings1.bottom;
	const bottom2 = boundings2.bottom;

	const overlap2 = Math.max(0, 1 - (bottom2 - bottom1) / (ele2.scrollHeight + 100));

	document.documentElement.style.setProperty(
		'--job-overlap', overlap2
	)
}

if (consultantComponent) {
	const consultantDecor = document.querySelector('#consultant-decor');

	window.addEventListener('scroll', () => {
		checkOverlap(consultantComponent, consultantDecor);
	});
}