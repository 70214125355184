// gsap
gsap.registerPlugin(ScrollTrigger);

if (ScrollTrigger.isTouch !== 1) {
  // image paralax

  /* home page */

  if (document.querySelector('solutions__bg'))
    gsap.fromTo(
      '.solutions__bg',
      {
        y: -200,
      },
      {
        y: 0,
        scrollTrigger: {
          trigger: '.solutions',
          end: 'top',
          scrub: true,
        },
      }
    );

  if (document.querySelector('solutions__bg'))
    gsap.fromTo(
      '.about__title',
      {
        x: 100,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: '.about',
          end: 'top',
          scrub: true,
        },
      }
    );

  if (document.querySelector('solutions__bg'))
    gsap.fromTo(
      '.about__decor-grid',
      {
        y: 100,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: '.about',
          end: 'top',
          scrub: true,
        },
      }
    );

  if (document.querySelector('solutions__bg'))
    gsap.fromTo(
      '.different__corner',
      {
        x: -200,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: '.different',
          end: 'top',
          scrub: true,
        },
      }
    );

  if (document.querySelector('solutions__bg'))
    gsap.fromTo(
      '.wedo__decor-grid',
      {
        y: -200,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: '.wedo',
          end: 'top',
          scrub: true,
        },
      }
    );

  if (document.querySelector('solutions__bg'))
    gsap.fromTo(
      '.blogs__decor',
      {
        y: -100,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: '.blogs',
          end: 'top',
          scrub: true,
        },
      }
    );

  if (document.querySelector('solutions__bg'))
    gsap.fromTo(
      '.blogs__corner',
      {
        y: 200,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: '.blogs',
          end: 'top',
          scrub: true,
        },
      }
    );

  if (document.querySelector('solutions__bg'))
    gsap.fromTo(
      '.stats__decor-grid',
      {
        y: -200,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: '.stats',
          end: 'top',
          scrub: true,
        },
      }
    );

  if (document.querySelector('solutions__bg'))
    gsap.fromTo(
      '.solutions__decor-grid',
      {
        y: -200,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: '.solutions',
          end: 'top',
          scrub: true,
        },
      }
    );

  if (document.querySelector('solutions__bg'))
    gsap.fromTo(
      '.different__t-decor',
      {
        y: -200,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: '.different',
          end: 'top',
          scrub: true,
        },
      }
    );

  /*and of home page*/

  /*about-us page*/
  if (document.querySelector('solutions__bg'))
    gsap.fromTo(
      '.offices-section-thumbnail',
      {
        x: 100,
      },
      {
        x: 0,
        scrollTrigger: {
          trigger: '.offices-section',
          end: 'top',
          scrub: true,
        },
      }
    );

  if (document.querySelector('solutions__bg'))
    gsap.fromTo(
      '.choose-section-thumbnail',
      {
        x: -100,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: '.choose-section',
          end: 'top',
          scrub: true,
        },
      }
    );
  if (document.querySelector('solutions__bg'))
    gsap.fromTo(
      '.choose-decor-thumbnail',
      {
        y: 200,
      },
      {
        y: 0,
        scrollTrigger: {
          trigger: '.choose-section-thumbnail',
          end: 'top',
          scrub: true,
        },
      }
    );

  if (document.querySelector('solutions__bg'))
    gsap.fromTo(
      '.dna-box-1',
      {
        x: -200,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: '.dna-section',
          end: 'top',
          scrub: true,
        },
      }
    );
  if (document.querySelector('solutions__bg'))
    gsap.fromTo(
      '.dna-box-2',
      {
        x: 200,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: '.dna-section',
          end: 'top',
          scrub: true,
        },
      }
    );
  if (document.querySelector('solutions__bg'))
    gsap.fromTo(
      '.ceo-section .title-decor svg',
      {
        x: 50,
        y: -50,
      },
      {
        x: 0,
        y: 0,
        scrollTrigger: {
          trigger: '.ceo-section .title',
          end: 'top',
          scrub: true,
        },
      }
    );
  if (document.querySelector('solutions__bg'))
    gsap.fromTo(
      '.ceo-section .title',
      {
        x: -100,
      },
      {
        x: 0,

        scrollTrigger: {
          trigger: '.ceo-section .title',
          end: 'top',
          scrub: true,
        },
      }
    );

  if (document.querySelector('solutions__bg'))
    gsap.fromTo(
      '.ceo-section-text-content',
      {
        x: -150,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: '.ceo-section-text',
          end: 'top',
          scrub: true,
        },
      }
    );

  if (document.querySelector('solutions__bg'))
    gsap.fromTo(
      '.ceo-section-thumbnail',
      {
        x: 150,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: '.ceo-section-text',
          end: 'top',
          scrub: true,
        },
      }
    );

  if (document.querySelector('solutions__bg'))
    gsap.fromTo(
      '.gs-section .title',
      {
        y: -150,
      },
      {
        y: 0,
        scrollTrigger: {
          trigger: '.gs-section',
          end: 'top',
          scrub: true,
        },
      }
    );

  if (document.querySelector('solutions__bg'))
    gsap.fromTo(
      '.gs-section-text',
      {
        y: -200,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: '.gs-section',
          end: 'top',
          scrub: true,
        },
      }
    );

  /*end of about-us page*/
  /* specialisms page*/
  if (document.querySelector('solutions__bg'))
    gsap.fromTo(
      '.jb-section .title',
      {
        x: -200,
      },
      {
        x: 0,
        scrollTrigger: {
          trigger: '.jb-section .title',
          end: 'top',
          scrub: true,
        },
      }
    );
  if (document.querySelector('solutions__bg'))
    gsap.fromTo(
      '.jb-section .swiper',
      {
        y: 200,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: '.jb-section .title',
          end: 'top',
          scrub: true,
        },
      }
    );
  if (document.querySelector('solutions__bg'))
    gsap.fromTo(
      '.dev-section-thumbnail',
      {
        x: -200,
      },
      {
        x: 0,
        scrollTrigger: {
          trigger: '.dev-section-thumbnail',
          end: 'top',
          scrub: true,
        },
      }
    );
  if (document.querySelector('solutions__bg'))
    gsap.fromTo(
      '.dev-section-text',
      {
        x: 200,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: '.dev-section-text',
          end: 'top',
          scrub: true,
        },
      }
    );
  if (document.querySelector('solutions__bg'))
    gsap.fromTo(
      '.dev-section-shape',
      {
        y: 200,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: '.dev-section-text-content',
          end: 'top',
          scrub: true,
        },
      }
    );
  if (document.querySelector('solutions__bg'))
    gsap.fromTo(
      '.different.different-specialisms .title',
      {
        x: -200,
      },
      {
        x: 0,
        scrollTrigger: {
          trigger: '.different.different-specialisms .title',
          end: 'top',
          scrub: true,
        },
      }
    );
  if (document.querySelector('solutions__bg'))
    gsap.fromTo(
      '.fit-section .title',
      {
        y: -200,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: '.fit-section .title',
          end: 'top',
          scrub: true,
        },
      }
    );
  if (document.querySelector('solutions__bg'))
    gsap.fromTo(
      '.fit-section-thumbnail',
      {
        x: 200,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: '.fit-section-thumbnail',
          end: 'top',
          scrub: true,
        },
      }
    );
  if (document.querySelector('solutions__bg'))
    gsap.fromTo(
      '.fit-section-thumbnail-decor',
      {
        y: 200,
      },
      {
        y: 0,
        scrollTrigger: {
          trigger: '.fit-section-thumbnail-decor',
          end: 'top',
          scrub: true,
        },
      }
    );
  if (document.querySelector('solutions__bg'))
    gsap.fromTo(
      '.fit-decor-t',
      {
        y: 100,
      },
      {
        y: 0,
        scrollTrigger: {
          trigger: '.fit-decor-t',
          end: 'top',
          scrub: true,
        },
      }
    );
  if (document.querySelector('solutions__bg'))
    gsap.fromTo(
      '.fit-section-text',
      {
        x: -200,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: '.fit-section-text',
          end: 'top',
          scrub: true,
        },
      }
    );
  if (document.querySelector('solutions__bg'))
    gsap.fromTo(
      '.article-thumbnail-shape',
      {
        x: -200,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: '.article-thumbnail-shape',
          end: 'top',
          scrub: true,
        },
      }
    );
  if (document.querySelector('solutions__bg'))
    gsap.fromTo(
      '.article-thumbnail',
      {
        x: 200,
      },
      {
        x: 0,
        scrollTrigger: {
          trigger: '.article-thumbnail',
          end: 'top',
          scrub: true,
        },
      }
    );
  if (document.querySelector('solutions__bg'))
    gsap.fromTo(
      '.article-content',
      {
        y: 200,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: '.article-content',
          end: 'top',
          scrub: true,
        },
      }
    );
  if (document.querySelector('solutions__bg'))
    gsap.fromTo(
      '.article-shape-bot',
      {
        y: 20,
        x: 50,
        opacity: 0,
      },
      {
        y: 0,
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: '.article-shape-bot',
          end: 'top',
          scrub: true,
        },
      }
    );
  if (document.querySelector('solutions__bg'))
    gsap.fromTo(
      '.blogs.blogs-article-page .blogs__title',
      {
        x: -150,
      },
      {
        x: 0,
        scrollTrigger: {
          trigger: '.blogs.blogs-article-page .blogs__title',
          end: 'top',
          scrub: true,
        },
      }
    );
  if (document.querySelector('solutions__bg'))
    gsap.fromTo(
      '.blogs.blogs-article-page .blogs__slider-box',
      {
        x: 150,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: '.blogs.blogs-article-page .blogs__title',
          end: 'top',
          scrub: true,
        },
      }
    );

  /* end of specialisms page*/
}
