let footer = document.getElementsByClassName('footer')[0];
let html = document.documentElement;
if(footer){
    window.addEventListener('scroll', function(){
        if(footer.offsetTop <= this.scrollY){
            html.classList.add('fixed-bg-hidden');

        }else{
            html.classList.remove('fixed-bg-hidden');

        }
    })
    var event = new Event("scroll");
    window.dispatchEvent(event);
}
